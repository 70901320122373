// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-downloads-tsx": () => import("/opt/build/repo/src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-expertise-tsx": () => import("/opt/build/repo/src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-formula-tsx": () => import("/opt/build/repo/src/pages/formula.tsx" /* webpackChunkName: "component---src-pages-formula-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profiles-tsx": () => import("/opt/build/repo/src/pages/profiles.tsx" /* webpackChunkName: "component---src-pages-profiles-tsx" */)
}

