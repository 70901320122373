/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import AppProvider from "./src/utils/providers/AppProvider"

const COMMIT = process.env.COMMIT_HASH || "N.A."
const VERSION = process.env.VERSION || "N.A."

export const onInitialClientRender = () => {
  console.info(`
    __            __        _                __   __
     | |  | |\\ | / _\` |    |__  |\\/| | |\\ | |  \\ /__\`
  \\__/ \\__/ | \\| \\__> |___ |___ |  | | | \\| |__/ .__/
  by: Jungle Minds
  Version: ${VERSION}
  Commit: ${COMMIT}
  `)
}

/* eslint-disable react/prop-types */
/**
 * Styles do not need to be re-rendered and thus sit together with Providers
 * and other top-level components
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
 */
export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
)
