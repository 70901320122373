import React from "react"

import { AppContextProvider } from "../context/AppContext"

interface IState {
  isMounted: boolean
}

class AppProvider extends React.PureComponent<{}, IState> {
  public state = {
    isMounted: false,
  }

  public componentDidMount() {
    this.setState({ isMounted: true })
  }

  public render() {
    return (
      <AppContextProvider value={this.state}>
        {this.props.children}
      </AppContextProvider>
    )
  }
}

export default AppProvider
